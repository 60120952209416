import React from "react"
import { Link } from "gatsby"
import "./Pagination.scss"

interface Props {
  pageContext: {
    previousPagePath: string
    nextPagePath: string
  }
}

const Pagination = ({ pageContext }: Props) => {
  const { previousPagePath, nextPagePath } = pageContext

  return (
    <nav className="pagination" role="navigation">
      <div>
        {previousPagePath && (
          <Link
            className="newer-posts button--secondary"
            to={previousPagePath}
            rel="prev"
          >
            Newer Posts
          </Link>
        )}
      </div>
      <div>
        {nextPagePath && (
          <Link
            className="older-posts button--secondary"
            to={nextPagePath}
            rel="next"
          >
            Older Posts
          </Link>
        )}
      </div>
    </nav>
  )
}

export default Pagination
