import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import MetaData from "../components/meta"
import PostCard from "../components/PostCard"
import Pagination from "../components/Pagination"
import { Helmet } from "react-helmet"

import "../styles/tag.scss"

const Tag = ({ data, location, pageContext }) => {
  const tag = data.ghostTag
  const posts = data.allGhostPost.edges

  return (
    <Layout>
      <MetaData data={data} location={location} type="series" />
      <Helmet>
        <meta name="robots" content="noindex, follow" />
      </Helmet>

      {/* Header */}
      <div
        className={
          "tag-header" + (tag.feature_image ? " with-cover-image" : "")
        }
        style={{
          backgroundImage: tag.feature_image
            ? `url(${tag.feature_image})`
            : `none`,
        }}
      >
        <div className="overlay">
          <div className="wrapper-1200">
            <h1 className="title">{tag.name}</h1>
            {tag.description && (
              <div className="description">{tag.description}</div>
            )}
          </div>
        </div>
      </div>

      {/* Posts */}
      <div className="tag-posts">
        <div className="wrapper-1200">
          <div className="post-feed">
            {posts.map(({ node }) => (
              <PostCard key={node.id} post={node} />
            ))}
          </div>
        </div>
      </div>

      <div className="tag-pagination">
        <div className="wrapper-1200">
          <Pagination pageContext={pageContext} />
        </div>
      </div>
    </Layout>
  )
}

export default Tag

export const pageQuery = graphql`
  query GhostTagQuery($slug: String!, $limit: Int!, $skip: Int!) {
    ghostTag(slug: { eq: $slug }) {
      ...GhostTagFields
    }
    allGhostPost(
      sort: { order: DESC, fields: [published_at] }
      filter: {
        primary_tag: { slug: { eq: "blog" } }
        tags: { elemMatch: { slug: { eq: $slug } } }
      }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...GhostPostCardFields
        }
      }
    }
  }
`
